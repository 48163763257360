@import "modules/variables.scss";
@import "modules/mixins.scss";

.offline-warning {
    background-color: rgba(red, 0.75);
    margin: 20px 15px 0 15px;
    padding: 20px;
    border-radius: 8px;
}

.animated {
    transform: translateY(0) !important;
    transition: transform 350ms;
}

.homepage-mobile {
    position: relative;
    //background-image: url('/ecran-connexion.png');
    //background-position: center center;
    //background-attachment: fixed;
    //background-repeat: no-repeat;
    //background-size: cover;
    margin: 0;
    padding: 0 0 50px 0;
    color: #fff;
    min-height: 100vh;
    transform: translateY(100vh);


    .logo-synchrone-mobile {
        padding: 50px 18vw 20px 18vw;

        img {
            width: 100%;
        }
    }

    &.homepage-mobile-contractor {
        color: $blueSynchrone;
        background-image: url('/img/background-contractor-w.png');

        @include onMobile {
            &:after {
                content: "";
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 0;
                background-image: url('/img/background-contractor-w.png');
                background-size: cover;
                background-position: center center;
            }
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 0;
            background: hsla(0, 0%, 100%, .75);
        }
    }

    .homepage-content {
        position: relative;
        z-index: 1;
    }

    .apps-container {
        margin-inline: 4vw;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        align-content: center;
        gap: 4vw;
        width: 92vw;
    }
}

h2 {
    padding-inline: 4vw;
    font-size: 20px;
    margin: 0;
    padding-block: 0.75em;
    font-weight: 400;
}

#logo {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-block: 30px;

    .angular-logo {
        max-width: 9.2rem;
    }
}


.news-container {
    padding-inline: 4vw;
    display: flex;
    flex-direction: column;

    img {
        width: 100%;
        margin: 0;
        padding: 0;
        border-radius: 6px 6px 0 0;
    }

    p {
        margin: 0;
        padding: 10px;
        background-color: #fff;
        color: #000;
        border-radius: 0 0 6px 6px;
    }
}

